import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetSessionData from './GetMeetSessionData';
import postMeetSessionData from './PostMeetSessionData'
import putMeetSessionData from './PutMeetSessionData';
import deleteMeetSessionData from './DeleteMeetSessionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: []
};

const OtsMeetSessionsData = {
  INITIAL_STATE, getMeetSessionData, postMeetSessionData, putMeetSessionData, deleteMeetSessionData
}

export default OtsMeetSessionsData;